import React, { useEffect, useLayoutEffect, useState } from 'react'
import './InternalAccessoriesScreen.css'
import { webSerialConnect } from '../../../../redux/actions/index'
import { connect } from 'react-redux'
import io from 'socket.io-client'
import cacheAssets from '../../../../utils/cacheAssets'
import renderCompImg from '../../../../source/Comp_Img'

import PortConnections from '../../../../Components/Assembly/PortConnections'
/* IMAGES  */
import Modal from 'react-modal'

import { internalaccessoriesObj } from './InternalAccessoriesStorage'
import { Link, useHistory } from 'react-router-dom'
import renderPrgImage from '../../../../source/programImg'
import InternalScPrgm from '../../../../Components/ReusableComponents/PrgmSlider/InternalPrgm/InternalScPrgm'
import { object } from 'prop-types'

import DialogModal from '../../../../Components/ReusableComponents/DialogModal/DialogModal'
import ProgramHeader from '../../../../Components/ReusableComponents/Header/ProgramHeader'
import Footer from '../../../../Components/ReusableComponents/FooterComponent'

//const socket = io.connect("http://localhost:3008");

// sessionStorage.setItem(
//   "interAccessoriesData",
//   JSON.stringify(internalaccessoriesObj)
// );

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        height: '23%',
        width: ' 25%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '5px solid rgb(255,140,25)',
        borderRadius: '20px',
        overflow: 'initial',
        // zIndex: 1,
    },
}

function InternalAccessoriesScreen_v1(props) {
    console.log(props)

    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const history = useHistory()
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [isDistanceSensors, setDistanceSensors] = useState(false)
    const [isGestureSensor, setGestureSensor] = useState(false)
    const [isLightSensor, setLightSensor] = useState(false)
    const [isColorSensor, setColorSensor] = useState(false)

    const [isTemperature, setTemperature] = useState(false)
    const [isBattery, setBattery] = useState(false)
    const [isAccelerometer, setAccelerometer] = useState(false)

    const [isMic, setMic] = useState(false)
    const [isSwitch1, setIsSwitch1] = useState(false)
    const [isSwitch2, setIsSwitch2] = useState(false)

    // input
    const [isTouchZero, setTouchZero] = useState(false)
    const [isTouchOne, setTouchOne] = useState(false)
    const [isTouchTwo, setTouchTwo] = useState(false)
    const [isTouchThree, setTouchThree] = useState(false)

    // outPut
    const [isTouchZeroOutput, setTouchZeroOutput] = useState(false)
    const [isTouchOneOutput, setTouchOneOutput] = useState(false)
    const [isTouchTwoOutput, setTouchTwoOutput] = useState(false)
    const [isTouchThreeOutput, setTouchThreeOutput] = useState(false)

    const [isEYES, setEye] = useState(false)
    const [isbuzzer, setbuzzer] = useState(false)

    const [isSmileOne, setSimleOne] = useState(false)
    const [isSmileTwo, setSimleTwo] = useState(false)
    const [isSmileThree, setSimleThree] = useState(false)
    const [isSmileFour, setSimleFour] = useState(false)

    const [isLeftEye, setIsLeftEye] = useState(false)
    const [isRightEye, setIsRightEye] = useState(false)

    const [erasedProgram, setErasedProgram] = useState(false)

    const [isusb, setUsb] = useState(false)
    const [reloaded, setReloaded] = useState(false)

    const [showFeedback, isShowFeedback] = useState(false)
    const [feedbackMsg, setFeedbackMsg] = useState('')

    const isPlayShield = sessionStorage.getItem('shield') === 'true' || false
    const isPeeCeeATR = sessionStorage.getItem('peeCeeATR') === 'true' || false
    const assembly = JSON.parse(sessionStorage.getItem('assembly'))
    const isM1DOccupied = assembly.PortConnections.M1 || false
    const isM2DOccupied = assembly.PortConnections.M2 || false
    const isM3DOccupied = assembly.PortConnections.M3 || false
    const isM4DOccupied = assembly.PortConnections.M4 || false
    const isM1 = sessionStorage.getItem('M1') === 'true' || false
    const isM2 = sessionStorage.getItem('M2') === 'true' || false
    const isM3 = sessionStorage.getItem('M3') === 'true' || false
    const isM4 = sessionStorage.getItem('M4') === 'true' || false

    const mode = sessionStorage.getItem('programMode')

    useEffect(() => {
        if (!showFeedback) return

        const timeout = setTimeout(() => {
            isShowFeedback(false)
        }, 1500)
        return () => {
            clearTimeout(timeout)
        }
    }, [showFeedback])
    // const [isColorSensor, setColorSensor] = useState(false);

    const styleInput = {
        width: '31vw',
        height: '32vw',
        position: 'relative',
        backgroundImage: `url(${renderPrgImage('popupcardType')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 90%',

        // backgroundSize: "contain",
    }

    const styleoutput = {
        width: '31vw',
        height: '32vw',
        position: 'relative',
        backgroundImage: `url(${renderPrgImage('popupcardType')})`,
        backgroundRepeat: 'no-repeat',
        // backgroundSize: "contain",
        backgroundSize: '100% 90%',
    }

    const styleDevicePC = {
        width: '25vw',
        height: '22vw',

        backgroundImage:
            connectedDevice === 'Roboki'
                ? `url(${renderPrgImage('Roboki')})`
                : `url(${renderPrgImage('pc_v1')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '76% 96%',
        backgroundPosition: '50% 50%',
        zIndex: 110,
        top: '4%',
        left: '-14%',
        position: 'relative',
        aspectRatio: '1 / 1',
    }

    useLayoutEffect(() => {
        setTouchZero(props.indexData.concept.internalaccessories.isTouchZero)
        setTouchOne(props.indexData.concept.internalaccessories.isTouchOne)
        setTouchTwo(props.indexData.concept.internalaccessories.isTouchTwo)
        setTouchThree(props.indexData.concept.internalaccessories.isTouchThree)

        setTouchZeroOutput(
            props.indexData.concept.internalaccessories.isTouchZeroOutput
        )
        setTouchOneOutput(
            props.indexData.concept.internalaccessories.isTouchOneOutput
        )
        setTouchTwoOutput(
            props.indexData.concept.internalaccessories.isTouchTwoOutput
        )
        setTouchThreeOutput(
            props.indexData.concept.internalaccessories.isTouchThreeOutput
        )
        setEye(props.indexData.concept.internalaccessories.isEYES)
        setbuzzer(props.indexData.concept.internalaccessories.isbuzzer)

        setSimleOne(props.indexData.concept.internalaccessories.isSmileOne)
        setSimleTwo(props.indexData.concept.internalaccessories.isSmileTwo)
        setSimleThree(props.indexData.concept.internalaccessories.isSmileThree)
        setSimleFour(props.indexData.concept.internalaccessories.isSmileFour)

        setLightSensor(
            props.indexData.concept.internalaccessories.Four_in_one_sensor
                .isLightSensor
        )
        setColorSensor(
            props.indexData.concept.internalaccessories.Four_in_one_sensor
                .isColorSensor
        )
        setGestureSensor(
            props.indexData.concept.internalaccessories.Four_in_one_sensor
                .isGestureSensor
        )
        setDistanceSensors(
            props.indexData.concept.internalaccessories.Four_in_one_sensor
                .isDistanceSensors
        )
        setTemperature(
            props.indexData.concept.internalaccessories.isTemperature
        )
        setBattery(props.indexData.concept.internalaccessories.isBattery)
        setMic(props.indexData.concept.internalaccessories.isMic)
        setAccelerometer(
            props.indexData.concept.internalaccessories.isAccelerometer
        )

        setIsSwitch1(props.indexData.concept.internalaccessories.isSwitch1)
        setIsSwitch2(props.indexData.concept.internalaccessories.isSwitch2)
        setIsLeftEye(props.indexData.concept.internalaccessories.isLeftEye)
        setIsRightEye(props.indexData.concept.internalaccessories.isRightEye)
    })
    useEffect(() => {
        console.log('PROPS', props.location.data)

        // if(props.location.data)
        let hhh = JSON.parse(sessionStorage.getItem('SavedGDriveData'))
        let names = JSON.parse(sessionStorage.getItem('saveProps')) || null
        // console.log("Names", hhh[0].name);
        if (hhh != null && names != null) {
            for (let i = 0; i < hhh.length; i++) {
                if (names.name == hhh[i].name) {
                    sessionStorage.setItem('pip', true)
                    sessionStorage.setItem('name', names.name)
                    console.log(
                        'KK',
                        hhh[i].concept.internalaccessories.isTouchOne
                    )
                    Object.keys(hhh[i]).map((key, value) => {
                        console.log('KEYS', key, value)
                        switch (key) {
                            case 'concept': {
                                sessionStorage.setItem(
                                    'concept',
                                    JSON.stringify(hhh[i].concept)
                                )
                            }
                            case 'assembly': {
                                sessionStorage.setItem(
                                    'assembly',
                                    JSON.stringify(hhh[i].assembly)
                                )
                            }
                            case 'logic': {
                                sessionStorage.setItem(
                                    'logic',
                                    JSON.stringify(hhh[i].logic)
                                )
                            }
                            case 'programEnd': {
                                sessionStorage.setItem(
                                    'programEnd',
                                    JSON.stringify(hhh[i].programEnd)
                                )
                            }
                            case 'SelectedStatus': {
                                sessionStorage.setItem(
                                    'SelectedStatus',
                                    JSON.stringify(hhh[i].SelectedStatus)
                                )
                            }
                            // case "concept": {
                            //   sessionStorage.setItem("concept", history.concept);
                            // }
                            // case "concept": {
                            //   sessionStorage.setItem("concept", history.concept);
                            // }
                        }
                    })
                    if (
                        sessionStorage.getItem('internalAcessoriesReload') ==
                        'true'
                    ) {
                        sessionStorage.setItem(
                            'internalAcessoriesReload',
                            false
                        )
                        window.location.reload()
                    }
                }
            }
        }
    })
    //useEffect to connect to pc automatically
    useEffect(async () => {
        navigator.serial.addEventListener('connect', (e) => {
            setUsb(true)
            var user = 1
            sessionStorage.setItem('user', JSON.stringify(user))
            if (window.location.pathname === '/deviceSelection') {
                sessionStorage.setItem('autoCunnection', 'true')
            }
            // const PLAY = [
            //   "P".charCodeAt(),
            //   "L".charCodeAt(),
            //   "A".charCodeAt(),
            //   "Y".charCodeAt(),
            // ];
            // setTimeout(() => {
            //   writePort(PLAY);
            // }, 1000);
        })

        navigator.serial.addEventListener('disconnect', async (e) => {
            setUsb(false)
            var user = 0
            sessionStorage.setItem('user', JSON.stringify(user))
            const p_Port = props.indexData.webSerial
            try {
                await p_Port.close()
            } catch (e) {}
        })
    })
    const removeFromProgram = async (e, obj) => {
        if (obj.length == 0) return
        for (var key in obj) {
            if (
                obj[key].type == 'hardware' ||
                obj[key].type == 'output' ||
                obj[key].type == 'variable_output'
            ) {
                if (e.slice(0, 5) === 'value') {
                    obj[key].state[e] = 0
                } else {
                    obj[key].state[e] = false
                }
            }
            if (
                obj[key].type == 'condition' ||
                obj[key].type == 'if' ||
                obj[key].type == 'else' ||
                obj[key].type == 'sensor' ||
                obj[key].type == 'variable' ||
                obj[key].type == 'loop'
            ) {
                await removeFromProgram(e, obj[key].subprogram)
            }
        }
    }
    //useeffect fo setting usb icon
    useEffect(() => {
        const p_Port = props.indexData.webSerial
        if (p_Port.readable != null) {
            let v = 1
            sessionStorage.setItem('user', JSON.stringify(v))
        }
    }, [])

    const clearFlowChartIfPannelData = (flowData, sensor) => {
        if (
            mode === 'flowchartBased' &&
            ![undefined, null].includes(flowData)
        ) {
            Object.values(JSON.parse(flowData)).map((value) => {
                if (
                    value.data !== undefined &&
                    value.data.specificElType === 'if'
                ) {
                    var id = value.id
                    if (
                        sensor.includes(sessionStorage.getItem(`ifSelect${id}`))
                    ) {
                        sessionStorage.setItem(`ifValue${id}`, 0)
                        sessionStorage.setItem(`ifValue2${id}`, 0)
                        sessionStorage.setItem(`gt${id}`, false)
                        sessionStorage.setItem(`lt${id}`, false)
                        sessionStorage.setItem(`bw${id}`, false)
                        sessionStorage.setItem(`eq${id}`, false)
                        sessionStorage.setItem(`ne${id}`, false)
                    }
                }
            })
        }
    }

    const handleFounInOneSensor = (e) => {
        const assembly = JSON.parse(sessionStorage.getItem('assembly'))
        if (
            assembly.PortConnections.D !== null &&
            assembly.PortConnections.D.type !== 'OLED'
        ) {
            setFeedbackMsg(
                'D port should be empty or can be connected with OLED inorder to enable FourInSensor'
            )
            isShowFeedback(true)
            return
        }

        switch (e.target.alt) {
            case 'distancesensors': {
                if (isDistanceSensors) {
                    internalaccessoriesObj.Four_in_one_sensor.isDistanceSensors = false
                    props.selecteComponent({ isDistanceSensors: false })
                    sessionStorage.setItem('isDistanceSensors', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            ['4-IN-1SENSOR→DISTANCE'].includes(
                                data.state.source.split(' ').join('')
                            )
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['distanceSensor']
                    )
                    props.logicComponent(props.indexData.logic)
                    setDistanceSensors(false)
                    var x = document.getElementById('snackbar18')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isDistanceSensors: true,
                        isColorSensor: false,
                        isLightSensor: false,
                        isGestureSensor: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    props.indexData.logic.program.map((data) => {
                        if (data.type === 'sensor') {
                            if (data.state.source.includes('4-IN-1 SENSOR')) {
                                data.state.source = ''
                                data.state.value = 0
                                data.state.value2 = 0
                                data.state.condition = ''
                            }
                        }
                    })
                    props.logicComponent(props.indexData.logic)

                    internalaccessoriesObj.Four_in_one_sensor.isColorSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isLightSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isGestureSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isDistanceSensors = true
                    setDistanceSensors(true)
                    setColorSensor(false)
                    setGestureSensor(false)
                    setLightSensor(false)
                    sessionStorage.setItem('isColorSensor', false)
                    sessionStorage.setItem('isLightSensor', false)
                    sessionStorage.setItem('isGestureSensor', false)
                    sessionStorage.setItem('isDistanceSensors', true)

                    var x = document.getElementById('snackbar18')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }

                break
            }
            case 'gesturesensor': {
                if (isGestureSensor) {
                    props.selecteComponent({ isGestureSensor: false })
                    internalaccessoriesObj.Four_in_one_sensor.isGestureSensor = false
                    setGestureSensor(false)
                    sessionStorage.setItem('isGestureSensor', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            ['4-IN-1SENSOR→GESTURE'].includes(
                                data.state.source.split(' ').join('')
                            )
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['gestureSensor']
                    )
                    props.logicComponent(props.indexData.logic)
                    var x = document.getElementById('snackbar19')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isDistanceSensors: false,
                        isColorSensor: false,
                        isLightSensor: false,
                        isGestureSensor: true,
                    })
                    //fix: playC1074 : state update on sensor change
                    props.indexData.logic.program.map((data) => {
                        if (data.type === 'sensor') {
                            if (data.state.source.includes('4-IN-1 SENSOR')) {
                                data.state.source = ''
                                data.state.value = 0
                                data.state.value2 = 0
                                data.state.condition = ''
                            }
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.Four_in_one_sensor.isColorSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isLightSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isGestureSensor = true
                    internalaccessoriesObj.Four_in_one_sensor.isDistanceSensors = false
                    setDistanceSensors(false)
                    setColorSensor(false)
                    setGestureSensor(true)
                    setLightSensor(false)
                    sessionStorage.setItem('isColorSensor', false)
                    sessionStorage.setItem('isLightSensor', false)
                    sessionStorage.setItem('isGestureSensor', true)
                    sessionStorage.setItem('isDistanceSensors', false)

                    var x = document.getElementById('snackbar19')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
            case 'lightsensor': {
                if (isLightSensor) {
                    internalaccessoriesObj.Four_in_one_sensor.isLightSensor = false
                    props.selecteComponent({ isLightSensor: false })
                    sessionStorage.setItem('isLightSensor', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            ['4-IN-1SENSOR→LIGHT'].includes(
                                data.state.source.split(' ').join('')
                            )
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['lightSensor']
                    )
                    props.logicComponent(props.indexData.logic)
                    setLightSensor(false)
                    var x = document.getElementById('snackbar20')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isDistanceSensors: false,
                        isColorSensor: false,
                        isLightSensor: true,
                        isGestureSensor: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    props.indexData.logic.program.map((data) => {
                        if (data.type === 'sensor') {
                            if (data.state.source.includes('4-IN-1 SENSOR')) {
                                data.state.source = ''
                                data.state.value = 0
                                data.state.value2 = 0
                                data.state.condition = ''
                            }
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.Four_in_one_sensor.isColorSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isLightSensor = true
                    internalaccessoriesObj.Four_in_one_sensor.isGestureSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isDistanceSensors = false
                    setDistanceSensors(false)
                    setColorSensor(false)
                    setGestureSensor(false)
                    setLightSensor(true)
                    sessionStorage.setItem('isColorSensor', false)
                    sessionStorage.setItem('isLightSensor', true)
                    sessionStorage.setItem('isGestureSensor', false)
                    sessionStorage.setItem('isDistanceSensors', false)

                    var x = document.getElementById('snackbar20')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
            case 'colorsensor': {
                if (isColorSensor) {
                    internalaccessoriesObj.Four_in_one_sensor.isColorSensor = false
                    props.selecteComponent({ isColorSensor: false })
                    sessionStorage.setItem('isColorSensor', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            [
                                '4-IN-1SENSOR→RED',
                                '4-IN-1SENSOR→BLUE',
                                '4-IN-1SENSOR→GREEN',
                            ].includes(data.state.source.split(' ').join(''))
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        [
                            'colorSensorRed',
                            'colorSensorGreen',
                            'colorSensorBlue',
                        ]
                    )
                    props.logicComponent(props.indexData.logic)
                    setColorSensor(false)
                    var x = document.getElementById('snackbar21')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isDistanceSensors: false,
                        isColorSensor: true,
                        isLightSensor: false,
                        isGestureSensor: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    props.indexData.logic.program.map((data) => {
                        if (data.type === 'sensor') {
                            if (data.state.source.includes('4-IN-1 SENSOR')) {
                                data.state.source = ''
                                data.state.value = 0
                                data.state.value2 = 0
                                data.state.condition = ''
                            }
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.Four_in_one_sensor.isColorSensor = true
                    internalaccessoriesObj.Four_in_one_sensor.isLightSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isGestureSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isDistanceSensors = false
                    setDistanceSensors(false)
                    setColorSensor(true)
                    setGestureSensor(false)
                    setLightSensor(false)
                    sessionStorage.setItem('isColorSensor', true)
                    sessionStorage.setItem('isLightSensor', false)
                    sessionStorage.setItem('isGestureSensor', false)
                    sessionStorage.setItem('isDistanceSensors', false)

                    var x = document.getElementById('snackbar21')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
        }
    }

    const handleEventsClick = async (e) => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        switch (e.target.alt) {
            case 'mic': {
                if (isMic) {
                    internalaccessoriesObj.isMic = false
                    props.selecteComponent({ isMic: false })
                    sessionStorage.setItem('isMic', false)
                    setMic(false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'MICROPHONE'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['microphone']
                    )
                    props.logicComponent(props.indexData.logic)

                    var x = document.getElementById('snackbar1')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    internalaccessoriesObj.isMic = true
                    props.selecteComponent({ isMic: true })
                    sessionStorage.setItem('isMic', true)
                    setMic(true)

                    var x = document.getElementById('snackbar1')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'temperature': {
                if (isTemperature) {
                    internalaccessoriesObj.isTemperature = false
                    props.selecteComponent({ isTemperature: false })
                    setTemperature(false)
                    sessionStorage.setItem('isTemperature', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'TEMPERATURE'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['temperature']
                    )
                    props.logicComponent(props.indexData.logic)

                    var x = document.getElementById('snackbar2')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    internalaccessoriesObj.isTemperature = true
                    props.selecteComponent({ isTemperature: true })
                    setTemperature(true)
                    sessionStorage.setItem('isTemperature', true)

                    var x = document.getElementById('snackbar2')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'battery': {
                if (isBattery) {
                    internalaccessoriesObj.isBattery = false
                    props.selecteComponent({ isBattery: false })
                    setBattery(false)
                    sessionStorage.setItem('isBattery', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'BATTERY'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['battery']
                    )
                    var x = document.getElementById('snackbar3')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    internalaccessoriesObj.isBattery = true
                    props.selecteComponent({ isBattery: true })
                    setBattery(true)
                    sessionStorage.setItem('isBattery', true)
                    props.logicComponent(props.indexData.logic)
                    var x = document.getElementById('snackbar3')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'accelerometer': {
                if (
                    assembly.PortConnections.D !== null &&
                    assembly.PortConnections.D.type !== 'OLED'
                ) {
                    setFeedbackMsg(
                        'D port should be empty or can be connected with OLED inorder to enable Accelerometer'
                    )
                    isShowFeedback(true)
                    return
                }
                if (isAccelerometer) {
                    internalaccessoriesObj.isAccelerometer = false
                    props.selecteComponent({ isAccelerometer: false })
                    setAccelerometer(false)
                    sessionStorage.setItem('isAccelerometer', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            [
                                'ACCELEROMETER_X',
                                'ACCELEROMETER_Y',
                                'ACCELEROMETER_Z',
                                'GYRO_X',
                                'GYRO_Y',
                                'GYRO_Z',
                            ].includes(data.state.source)
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        [
                            'accelerometer_x',
                            'accelerometer_y',
                            'accelerometer_z',
                            'gyro_x',
                            'gyro_y',
                            'gyro_z',
                        ]
                    )
                    props.logicComponent(props.indexData.logic)

                    var x = document.getElementById('snackbar4')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    internalaccessoriesObj.isAccelerometer = true
                    props.selecteComponent({ isAccelerometer: true })
                    setAccelerometer(true)
                    sessionStorage.setItem('isAccelerometer', true)

                    var x = document.getElementById('snackbar4')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch0': {
                if (JSON.parse(sessionStorage.getItem('S1'))) {
                    let x = document.getElementById('snackbar3_A1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Please disable playshield in assembly screen to enable touch0'
                    )
                    isShowFeedback(true)
                    break
                }
                if (isPeeCeeATR) {
                    setFeedbackMsg(
                        'Please disable peeCeeATR in assembly screen to enable touch0'
                    )
                    isShowFeedback(true)
                    break
                }

                if (isTouchZero) {
                    props.selecteComponent({ isTouchZero: false })
                    internalaccessoriesObj.isTouchZero = false
                    setTouchZero(false)
                    sessionStorage.setItem('isTouchZero', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'TouchZero'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['touchZero']
                    )
                    props.logicComponent(props.indexData.logic)

                    var x = document.getElementById('snackbar5')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isTouchZero: true,
                        isTouchZeroOutput: false,
                    })

                    internalaccessoriesObj.isTouchZero = true
                    internalaccessoriesObj.isTouchZeroOutput = false
                    setTouchZero(true)
                    setTouchZeroOutput(false)
                    sessionStorage.setItem('isTouchZero', true)
                    sessionStorage.setItem('isTouchZeroOutput', false)

                    var x = document.getElementById('snackbar5')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch1': {
                if (JSON.parse(sessionStorage.getItem('S2'))) {
                    let x = document.getElementById('snackbar7_B1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }

                if (isPlayShield) {
                    setFeedbackMsg(
                        'Please disable playshield in assembly screen to enable touch1'
                    )
                    isShowFeedback(true)
                    break
                }
                if (isPeeCeeATR) {
                    setFeedbackMsg(
                        'Please disable peeCeeATR in assembly screen to enable touch0'
                    )
                    isShowFeedback(true)
                    break
                }

                if (isTouchOne) {
                    props.selecteComponent({ isTouchOne: false })
                    internalaccessoriesObj.isTouchOne = false
                    setTouchOne(false)
                    sessionStorage.setItem('isTouchOne', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'TouchOne'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['touchOne']
                    )
                    props.logicComponent(props.indexData.logic)

                    var x = document.getElementById('snackbar6')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isTouchOne: true,
                        isTouchOneOutput: false,
                    })
                    internalaccessoriesObj.isTouchOne = true
                    internalaccessoriesObj.isTouchOneOutput = false
                    setTouchOne(true)
                    setTouchOneOutput(false)
                    sessionStorage.setItem('isTouchOne', true)
                    sessionStorage.setItem('isTouchOneOutput', false)

                    var x = document.getElementById('snackbar6')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch2': {
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Please disable playshield in assembly screen to enable touch2'
                    )
                    isShowFeedback(true)
                    break
                }
                if (isPeeCeeATR) {
                    setFeedbackMsg(
                        'Please disable peeCeeATR in assembly screen to enable touch0'
                    )
                    isShowFeedback(true)
                    break
                }

                if (isTouchTwo) {
                    console.log('true')
                    props.selecteComponent({ isTouchTwo: false })
                    internalaccessoriesObj.isTouchTwo = false
                    setTouchTwo(false)
                    sessionStorage.setItem('isTouchTwo', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'TouchTwo'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['touchTwo']
                    )
                    props.logicComponent(props.indexData.logic)

                    var x = document.getElementById('snackbar7')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    console.log('false')
                    props.selecteComponent({
                        isTouchTwo: true,
                        isTouchTwoOutput: false,
                    })
                    internalaccessoriesObj.isTouchTwo = true
                    internalaccessoriesObj.isTouchTwoOutput = false
                    setTouchTwo(true)
                    setTouchTwoOutput(false)
                    sessionStorage.setItem('isTouchTwo', true)
                    sessionStorage.setItem('isTouchTwoOutput', false)

                    var x = document.getElementById('snackbar7')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch3': {
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Please disable playshield in assembly screen to enable touch3'
                    )
                    isShowFeedback(true)
                    break
                }
                if (isPeeCeeATR) {
                    setFeedbackMsg(
                        'Please disable peeCeeATR in assembly screen to enable touch0'
                    )
                    isShowFeedback(true)
                    break
                }
                if (isTouchThree) {
                    props.selecteComponent({ isTouchThree: false })
                    internalaccessoriesObj.isTouchThree = false
                    setTouchThree(false)
                    sessionStorage.setItem('isTouchThree', false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'TouchThree'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['touchThree']
                    )
                    props.logicComponent(props.indexData.logic)

                    var x = document.getElementById('snackbar8')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isTouchThree: true,
                        isTouchThreeOutput: false,
                    })
                    internalaccessoriesObj.isTouchThree = true
                    internalaccessoriesObj.isTouchThreeOutput = false
                    setTouchThree(true)
                    setTouchThreeOutput(false)
                    sessionStorage.setItem('isTouchThree', true)
                    sessionStorage.setItem('isTouchThreeOutput', false)

                    var x = document.getElementById('snackbar8')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch0Output': {
                if (JSON.parse(sessionStorage.getItem('S1'))) {
                    let x = document.getElementById('snackbar3_A1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }

                if (isPlayShield) {
                    setFeedbackMsg(
                        'Please disable playshield in assembly screen to enable touch0Output'
                    )
                    isShowFeedback(true)
                    break
                }
                if (isPeeCeeATR) {
                    setFeedbackMsg(
                        'Please disable peeCeeATR in assembly screen to enable touch0'
                    )
                    isShowFeedback(true)
                    break
                }

                console.log(props, 'select props')
                if (isTouchZeroOutput) {
                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignTouchZeroOutput',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueTouchZeroOutput',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isTouchZeroOutput: false })
                    //////////////////////////////////////////////////////////////////////////////
                    internalaccessoriesObj.isTouchZeroOutput = false
                    setTouchZeroOutput(false)
                    sessionStorage.setItem('isTouchZeroOutput', false)

                    var x = document.getElementById('snackbar9')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    if (
                        sessionStorage.getItem('programMode') ===
                        'flowchartBased'
                    ) {
                        const flowElements =
                            JSON.parse(
                                sessionStorage.getItem('flowchart-elements')
                            ) || null
                        if (flowElements !== null) {
                            flowElements.map((value) => {
                                if (value.type === 'output') {
                                    sessionStorage.setItem(
                                        `t0Chk${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(`t0${value.id}`, 0)
                                }
                            })
                        }
                    }
                } else {
                    props.selecteComponent({
                        isTouchZeroOutput: true,
                        isTouchZero: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    //touchZero input -false
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source == 'TouchZero'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignTouchZeroOutput = false
                        }
                    } //for sim

                    internalaccessoriesObj.isTouchZeroOutput = true
                    internalaccessoriesObj.isTouchZero = false
                    setTouchZeroOutput(true)
                    setTouchZero(false)
                    sessionStorage.setItem('isTouchZeroOutput', true)
                    sessionStorage.setItem('isTouchZero', false)

                    var x = document.getElementById('snackbar9')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch1Output': {
                if (JSON.parse(sessionStorage.getItem('S2'))) {
                    let x = document.getElementById('snackbar7_B1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }

                if (isPlayShield) {
                    setFeedbackMsg(
                        'Please disable playshield in assembly screen to enable touch1Output'
                    )
                    isShowFeedback(true)
                    break
                }
                if (isPeeCeeATR) {
                    setFeedbackMsg(
                        'Please disable peeCeeATR in assembly screen to enable touch0'
                    )
                    isShowFeedback(true)
                    break
                }

                if (isTouchOneOutput) {
                    internalaccessoriesObj.isTouchOneOutput = false
                    setTouchOneOutput(false)
                    sessionStorage.setItem('isTouchOneOutput', false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignTouchOneOutput',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueTouchOneOutput',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isTouchOneOutput: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar10')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    if (
                        sessionStorage.getItem('programMode') ===
                        'flowchartBased'
                    ) {
                        const flowElements =
                            JSON.parse(
                                sessionStorage.getItem('flowchart-elements')
                            ) || null
                        if (flowElements !== null) {
                            flowElements.map((value) => {
                                if (value.type === 'output') {
                                    sessionStorage.setItem(
                                        `t1Chk${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(`t1${value.id}`, 0)
                                }
                            })
                        }
                    }
                } else {
                    props.selecteComponent({
                        isTouchOneOutput: true,
                        isTouchOne: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    //touchZero input -false
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source == 'TouchOne'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.isTouchOneOutput = true
                    internalaccessoriesObj.isTouchOne = false

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignTouchOneOutput = false
                        }
                    } //for sim

                    setTouchOneOutput(true)
                    setTouchOne(false)
                    sessionStorage.setItem('isTouchOneOutput', true)
                    sessionStorage.setItem('isTouchOne', false)

                    var x = document.getElementById('snackbar10')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch2Output': {
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Please disable playshield in assembly screen to enable touch2Output'
                    )
                    isShowFeedback(true)
                    break
                }
                if (isPeeCeeATR) {
                    setFeedbackMsg(
                        'Please disable peeCeeATR in assembly screen to enable touch0'
                    )
                    isShowFeedback(true)
                    break
                }

                if (isTouchTwoOutput) {
                    internalaccessoriesObj.isTouchTwoOutput = false
                    setTouchTwoOutput(false)
                    sessionStorage.setItem('isTouchTwoOutput', false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignTouchTwoOutput',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueTouchTwoOutput',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isTouchTwoOutput: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar11')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    if (
                        sessionStorage.getItem('programMode') ===
                        'flowchartBased'
                    ) {
                        const flowElements =
                            JSON.parse(
                                sessionStorage.getItem('flowchart-elements')
                            ) || null
                        if (flowElements !== null) {
                            flowElements.map((value) => {
                                if (value.type === 'output') {
                                    sessionStorage.setItem(
                                        `t2Chk${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(`t2${value.id}`, 0)
                                }
                            })
                        }
                    }
                } else {
                    props.selecteComponent({
                        isTouchTwoOutput: true,
                        isTouchTwo: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    //touchZero input -false
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source == 'TouchTwo'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.isTouchTwoOutput = true
                    internalaccessoriesObj.isTouchTwo = false

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignTouchTwoOutput = false
                        }
                    } //for sim

                    setTouchTwoOutput(true)
                    setTouchTwo(false)
                    sessionStorage.setItem('isTouchTwoOutput', true)
                    sessionStorage.setItem('isTouchTwo', false)

                    var x = document.getElementById('snackbar11')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch3Output': {
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Please disable playshield in assembly screen to enable touch1Output'
                    )
                    isShowFeedback(true)
                    break
                }
                if (isPeeCeeATR) {
                    setFeedbackMsg(
                        'Please disable peeCeeATR in assembly screen to enable touch0'
                    )
                    isShowFeedback(true)
                    break
                }

                if (isTouchThreeOutput) {
                    internalaccessoriesObj.isTouchThreeOutput = false
                    setTouchThreeOutput(false)
                    sessionStorage.setItem('isTouchThreeOutput', false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignTouchThreeOutput',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueTouchThreeOutput',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isTouchThreeOutput: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar22')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    if (
                        sessionStorage.getItem('programMode') ===
                        'flowchartBased'
                    ) {
                        const flowElements =
                            JSON.parse(
                                sessionStorage.getItem('flowchart-elements')
                            ) || null
                        if (flowElements !== null) {
                            flowElements.map((value) => {
                                if (value.type === 'output') {
                                    sessionStorage.setItem(
                                        `t3Chk${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(`t3${value.id}`, 0)
                                }
                            })
                        }
                    }
                } else {
                    props.selecteComponent({
                        isTouchThreeOutput: true,
                        isTouchThree: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    //touchZero input -false
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source == 'TouchThree'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.isTouchThreeOutput = true
                    internalaccessoriesObj.isTouchThree = false

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignTouchThreeOutput = false
                        }
                    } //for sim

                    setTouchThreeOutput(true)
                    setTouchThree(false)
                    sessionStorage.setItem('isTouchThreeOutput', true)
                    sessionStorage.setItem('isTouchThree', false)

                    var x = document.getElementById('snackbar22')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'isEYES': {
                if (isEYES) {
                    internalaccessoriesObj.isEYES = false
                    setEye(false)
                    sessionStorage.setItem('isInternalEye', false)
                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    for (let i = 1; i <= 12; i++) {
                        await removeFromProgram(
                            `assignEye${i}`,
                            props.indexData.logic.program
                        )
                        await removeFromProgram(
                            `valueEyeR${i}`,
                            props.indexData.logic.program
                        )
                        await removeFromProgram(
                            `valueEyeG${i}`,
                            props.indexData.logic.program
                        )
                        await removeFromProgram(
                            `valueEyeB${i}`,
                            props.indexData.logic.program
                        )
                    }
                    props.selecteComponent({ isEYES: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar12')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({ isEYES: true })

                    internalaccessoriesObj.isEYES = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        // console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignEye = false
                        }
                    } //for sim

                    setEye(true)
                    sessionStorage.setItem('isInternalEye', true)

                    var x = document.getElementById('snackbar12')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    if (
                        sessionStorage.getItem('programMode') ===
                        'flowchartBased'
                    ) {
                        const flowElements =
                            JSON.parse(
                                sessionStorage.getItem('flowchart-elements')
                            ) || null
                        if (flowElements !== null) {
                            flowElements.map((value) => {
                                if (value.type === 'output') {
                                    sessionStorage.setItem(
                                        `valRGBEye${value.id}`,
                                        JSON.stringify([
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                            {
                                                isSelected: false,
                                                valR: 0,
                                                valG: 0,
                                                valB: 0,
                                            },
                                        ])
                                    )
                                }
                            })
                        }
                    }
                }
                break
            }

            case 'buzzer': {
                if (isbuzzer) {
                    internalaccessoriesObj.isbuzzer = false
                    setbuzzer(false)
                    sessionStorage.setItem('isBuzzer', false)
                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignBuzzer',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignBuzzerFrequency',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignBuzzerTone',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueBuzzerFrequency',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueBuzzerTone',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isbuzzer: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar13')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    if (
                        sessionStorage.getItem('programMode') ===
                        'flowchartBased'
                    ) {
                        const flowElements =
                            JSON.parse(
                                sessionStorage.getItem('flowchart-elements')
                            ) || null
                        if (flowElements !== null) {
                            flowElements.map((value) => {
                                if (value.type === 'output') {
                                    sessionStorage.setItem(
                                        `toneBuzzCheck${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(
                                        `frequencyBuzzCheck${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(
                                        `buzzChk${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(`buzz${value.id}`, 0)
                                }
                            })
                        }
                    }
                } else {
                    props.selecteComponent({ isbuzzer: true })

                    internalaccessoriesObj.isbuzzer = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignBuzzer = false
                        }
                    } //for sim

                    setbuzzer(true)
                    sessionStorage.setItem('isBuzzer', true)

                    var x = document.getElementById('snackbar13')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'smile1': {
                if (isM1) {
                    setFeedbackMsg(' Disable L1 to Enable Smile 1')
                    isShowFeedback(true)
                    return
                }

                if (isM1DOccupied) {
                    setFeedbackMsg(
                        'Disconnect the sensor from port M1 to enable smile1'
                    )
                    isShowFeedback(true)
                    return
                }

                if (isSmileOne) {
                    internalaccessoriesObj.isSmileOne = false
                    setSimleOne(false)
                    sessionStorage.setItem('isSmileOne', false)
                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignSmileOne',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueSmileOne',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isSmileOne: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar14')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    if (
                        sessionStorage.getItem('programMode') ===
                        'flowchartBased'
                    ) {
                        const flowElements =
                            JSON.parse(
                                sessionStorage.getItem('flowchart-elements')
                            ) || null
                        if (flowElements !== null) {
                            flowElements.map((value) => {
                                if (value.type === 'output') {
                                    sessionStorage.setItem(
                                        `s1Chk${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(`sm1${value.id}`, 0)
                                }
                            })
                        }
                    }
                } else {
                    props.selecteComponent({ isSmileOne: true })
                    internalaccessoriesObj.isSmileOne = true
                    setSimleOne(true)
                    sessionStorage.setItem('isSmileOne', true)

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignSmileOne = false
                        }
                    } //for sim

                    var x = document.getElementById('snackbar14')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'smile2': {
                if (isM2) {
                    setFeedbackMsg(' Disable L2 to Enable Smile 3')
                    isShowFeedback(true)
                    return
                }
                if (isM2DOccupied) {
                    setFeedbackMsg(
                        'Disconnect the sensor from port M1 to enable smile2'
                    )
                    isShowFeedback(true)
                    return
                }
                if (isSmileTwo) {
                    internalaccessoriesObj.isSmileTwo = false
                    setSimleTwo(false)
                    sessionStorage.setItem('isSmileTwo', false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignSmileTwo',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueSmileTwo',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isSmileTwo: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar15')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    if (
                        sessionStorage.getItem('programMode') ===
                        'flowchartBased'
                    ) {
                        const flowElements =
                            JSON.parse(
                                sessionStorage.getItem('flowchart-elements')
                            ) || null
                        if (flowElements !== null) {
                            flowElements.map((value) => {
                                if (value.type === 'output') {
                                    sessionStorage.setItem(
                                        `s2Chk${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(`sm2${value.id}`, 0)
                                }
                            })
                        }
                    }
                } else {
                    props.selecteComponent({ isSmileTwo: true })

                    internalaccessoriesObj.isSmileTwo = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignSmileTwo = false
                        }
                    } //for sim

                    setSimleTwo(true)
                    sessionStorage.setItem('isSmileTwo', true)

                    var x = document.getElementById('snackbar15')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'smile3': {
                if (isM3) {
                    setFeedbackMsg(' Disable L3 to Enable Smile 3')
                    isShowFeedback(true)
                    return
                }
                if (isM3DOccupied) {
                    setFeedbackMsg(
                        'Disconnect the sensor from port M2 to enable smile2'
                    )
                    isShowFeedback(true)
                    return
                }
                if (isSmileThree) {
                    internalaccessoriesObj.isSmileThree = false
                    setSimleThree(false)
                    sessionStorage.setItem('isSmileThree', false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignSmileThree',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueSmileThree',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isSmileThree: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar16')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    if (
                        sessionStorage.getItem('programMode') ===
                        'flowchartBased'
                    ) {
                        const flowElements =
                            JSON.parse(
                                sessionStorage.getItem('flowchart-elements')
                            ) || null
                        if (flowElements !== null) {
                            flowElements.map((value) => {
                                if (value.type === 'output') {
                                    sessionStorage.setItem(
                                        `s3Chk${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(`sm3${value.id}`, 0)
                                }
                            })
                        }
                    }
                } else {
                    props.selecteComponent({ isSmileThree: true })

                    internalaccessoriesObj.isSmileThree = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignSmileThree = false
                        }
                    } //for sim

                    setSimleThree(true)
                    sessionStorage.setItem('isSmileThree', true)

                    var x = document.getElementById('snackbar16')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }

                break
            }

            case 'smile4': {
                if (isM4) {
                    setFeedbackMsg(' Disable L4 to Enable Smile 4')
                    isShowFeedback(true)
                    return
                }
                if (isM4DOccupied) {
                    setFeedbackMsg(
                        'Disconnect the sensor from port M2 to enable smile4'
                    )
                    isShowFeedback(true)
                    return
                }
                if (isSmileFour) {
                    internalaccessoriesObj.isSmileFour = false
                    setSimleFour(false)
                    sessionStorage.setItem('isSmileFour', false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignSmileFour',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueSmileFour',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isSmileFour: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar17')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    if (
                        sessionStorage.getItem('programMode') ===
                        'flowchartBased'
                    ) {
                        const flowElements =
                            JSON.parse(
                                sessionStorage.getItem('flowchart-elements')
                            ) || null
                        if (flowElements !== null) {
                            flowElements.map((value) => {
                                if (value.type === 'output') {
                                    sessionStorage.setItem(
                                        `s4Chk${value.id}`,
                                        false
                                    )
                                    sessionStorage.setItem(`sm4${value.id}`, 0)
                                }
                            })
                        }
                    }
                } else {
                    props.selecteComponent({ isSmileFour: true })

                    internalaccessoriesObj.isSmileFour = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignSmileFour = false
                        }
                    } //for sim

                    setSimleFour(true)
                    sessionStorage.setItem('isSmileFour', true)

                    var x = document.getElementById('snackbar17')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'switch1': {
                if (isSwitch1) {
                    internalaccessoriesObj.isSwitch1 = false
                    props.selecteComponent({ isSwitch1: false })
                    sessionStorage.setItem('isSwitch1', false)
                    setIsSwitch1(false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'SWITCH1'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['isSwitch1']
                    )
                    props.logicComponent(props.indexData.logic)

                    var x = document.getElementById('snackbar23')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    internalaccessoriesObj.isSwitch1 = true
                    props.selecteComponent({ isSwitch1: true })
                    sessionStorage.setItem('isSwitch1', true)
                    setIsSwitch1(true)

                    var x = document.getElementById('snackbar23')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
            case 'switch2': {
                if (isSwitch2) {
                    internalaccessoriesObj.isSwitch2 = false
                    props.selecteComponent({ isSwitch2: false })
                    sessionStorage.setItem('isSwitch2', false)
                    setIsSwitch2(false)
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'SWITCH2'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['isSwitch2']
                    )
                    props.logicComponent(props.indexData.logic)

                    var x = document.getElementById('snackbar24')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    internalaccessoriesObj.isSwitch2 = true
                    props.selecteComponent({ isSwitch2: true })
                    sessionStorage.setItem('isSwitch2', true)
                    setIsSwitch2(true)

                    var x = document.getElementById('snackbar24')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
            case 'leftEye': {
                if (isLeftEye) {
                    internalaccessoriesObj.isLeftEye = false
                    setIsLeftEye(false)
                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignEye1',
                        props.indexData.logic.program
                    )

                    await removeFromProgram(
                        'assignR1',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignG1',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignB1',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueEyeR1',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueEyeG1',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueEyeB1',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isLeftEye: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar25')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({ isLeftEye: true })

                    internalaccessoriesObj.isLeftEye = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignLeftEye = false
                            props.indexData.logic.program[
                                key
                            ].state.assignLeftEyeR = false
                            props.indexData.logic.program[
                                key
                            ].state.assignLeftEyeG = false
                            props.indexData.logic.program[
                                key
                            ].state.assignLeftEyeB = false
                        }
                    } //for sim

                    setIsLeftEye(true)
                    var x = document.getElementById('snackbar25')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'rightEye': {
                if (isRightEye) {
                    internalaccessoriesObj.isRightEye = false
                    setIsRightEye(false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignEye2',
                        props.indexData.logic.program
                    )

                    await removeFromProgram(
                        'assignR2',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignG2',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignB2',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueEyeR2',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueEyeG2',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueEyeB2',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isRightEye: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar26')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({ isRightEye: true })

                    internalaccessoriesObj.isRightEye = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignRightEye = false
                            props.indexData.logic.program[
                                key
                            ].state.assignRightEyeR = false
                            props.indexData.logic.program[
                                key
                            ].state.assignRightEyeG = false
                            props.indexData.logic.program[
                                key
                            ].state.assignRightEyeB = false
                        }
                    } //for sim

                    setIsRightEye(true)
                    var x = document.getElementById('snackbar26')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
        }
        saveToLocalStorage()
    }

    const saveToLocalStorage = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion

        let updatelocalStorage = JSON.parse(
            localStorage.getItem('recoveryData')
        )
        if (updatelocalStorage) {
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
    }
    const backBtnAction = () => {
        setSlideDirection(true)
        sessionStorage.setItem('slideDirection', true)
        let saved = JSON.parse(sessionStorage.getItem('isSave'))
        if (saved) {
            props.history.push('/code/project')
            // window.location.reload(false)
            shouldErase('Yes')
        } else {
            setErasedProgram(true)
        }
    }

    const [isHelp, setHelp] = useState(false)

    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    const shouldErase = (info) => {
        if (info == 'Yes') {
            let programMode = sessionStorage.programMode
            let connectedDevice = sessionStorage.connectedDevice
            let version = sessionStorage.deviceVersion
            let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
            sessionStorage.setItem('isSave', JSON.stringify(false))
            if (
                localStorage.getItem('recoveryData') !== null &&
                programMode in recoveryData &&
                connectedDevice in recoveryData[programMode] &&
                version in recoveryData[programMode][connectedDevice]
            ) {
                if (Object.keys(recoveryData).length === 1) {
                    if (Object.keys(recoveryData[programMode]).length === 1) {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            localStorage.removeItem('recoveryData')
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                            localStorage.setItem(
                                'recoveryData',
                                JSON.stringify(recoveryData)
                            )
                        }
                    } else {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode][connectedDevice]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                } else {
                    if (Object.keys(recoveryData[programMode]).length === 1) {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    } else {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode][connectedDevice]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                }
            }
            if (programMode === 'flowchartBased') {
                var arr = Object.keys(sessionStorage)
                for (const i of arr) {
                    if (
                        i != 'connectedDevice' &&
                        i != 'Hardware' &&
                        i != 'userData' &&
                        i != 'concept' &&
                        i != 'webSerialPortList' &&
                        i != 'user' &&
                        i != 'deviceVersion' &&
                        i != 'programMode' &&
                        i != 'isWindowOnFocus' &&
                        i != 'slideDirection' &&
                        i != 'assembly'
                    ) {
                        sessionStorage.removeItem(i)
                    }
                }
                props.history.push('/flow')
                // window.location.reload()
            } else {
                sessionStorage.removeItem('programEnd')
                sessionStorage.removeItem('PET')
                sessionStorage.removeItem('EndSwitch')
                sessionStorage.setItem('pip', false)
                sessionStorage.setItem('name', 'null')
                sessionStorage.removeItem('Index')
                sessionStorage.setItem('shield', 'false')
                props.indexData.concept.Index = []
                props.history.push('/code/project')
                // window.location.reload()
            }
            sessionStorage.setItem('peeCeeATR', 'false')
            sessionStorage.removeItem('flowchart-elements')
            sessionStorage.removeItem('isMic')
            sessionStorage.removeItem('isTemperature')

            sessionStorage.removeItem('isAccelerometer')
            sessionStorage.removeItem('isTouchZero')

            sessionStorage.removeItem('isTouchZeroOutput')
            sessionStorage.removeItem('isTouchOne')
            sessionStorage.removeItem('isInternalEye')
            sessionStorage.removeItem('isTouchOneOutput')
            sessionStorage.removeItem('isTouchTwo')
            sessionStorage.removeItem('isTouchTwoOutput')
            sessionStorage.removeItem('isTouchThreeOutput')
            sessionStorage.removeItem('isTouchThree')
            sessionStorage.removeItem('isColorSensor')
            sessionStorage.removeItem('isBattery')
            sessionStorage.removeItem('isGestureSensor')
            sessionStorage.removeItem('isLightSensor')
            sessionStorage.removeItem('isDistanceSensors')
            sessionStorage.removeItem('isSmileOne')
            sessionStorage.removeItem('isSmileTwo')
            sessionStorage.removeItem('isSmileThree')
            sessionStorage.removeItem('isSmileFour')
            sessionStorage.removeItem('isBuzzer')
            sessionStorage.removeItem('isSwitch1')
            sessionStorage.removeItem('isSwitch2')

            props.DeselectedComponent({
                Four_in_one_sensor: {
                    isDistanceSensors: false,
                    isGestureSensor: false,
                    isLightSensor: false,
                    isColorSensor: false,
                },
                isFour_in_one_sensor: false,

                isMic: false,
                isTemperature: false,
                isBattery: false,
                isAccelerometer: false,

                // input
                isTouchZero: false,
                isTouchOne: false,
                isTouchTwo: false,
                isTouchThree: false,

                // output
                isTouchZeroOutput: false,
                isTouchOneOutput: false,
                isTouchTwoOutput: false,
                isTouchThreeOutput: false,

                isLeftEye: false,
                isRightEye: false,
                isbuzzer: false,

                isSmileOne: false,
                isSmileTwo: false,
                isSmileThree: false,
                isSmileFour: false,
                isEYES: false,
                isSwitch1: false,
                isSwitch2: false,
            })
        } else {
            setErasedProgram(false)
        }
    }

    const next = () => {
        setSlideDirection(false)
        sessionStorage.setItem('slideDirection', false)
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion

        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
        if (sessionStorage.getItem('programMode') === 'flowchartBased')
            history.push('/flow/selectports')
        else history.push('/selectScreen/ExternalAccessories')
    }

    sessionStorage.setItem('assemblyCheckbox', JSON.stringify(null))

    useEffect(() => {
        if (
            isDistanceSensors ||
            isGestureSensor ||
            isLightSensor ||
            isColorSensor
        ) {
            internalaccessoriesObj.isFour_in_one_sensor = true
            props.selecteComponent({ isFour_in_one_sensor: true })
        } else {
            internalaccessoriesObj.isFour_in_one_sensor = false
            props.selecteComponent({ isFour_in_one_sensor: false })
        }
    }, [isDistanceSensors, isGestureSensor, isLightSensor, isColorSensor])

    // Caching External Component assets

    useEffect(() => {
        let assetsToBeCached = [
            renderCompImg('pot'),
            renderCompImg('play_shield'),
            renderCompImg('play_shield_v1_top'),
            renderCompImg('play_shield_top'),
            renderCompImg('dc_motor'),
            renderCompImg('dynamex_motor'),
            renderCompImg('geared_motor'),
            renderCompImg('mini_geared_motor'),
            renderCompImg('distance_sensor'),
            renderCompImg('dual_switch'),
            renderCompImg('joystick'),
            renderCompImg('laser'),
            renderCompImg('led'),
            renderCompImg('servo_motor'),
            renderCompImg('servo_motor_270'),
            renderCompImg('servo_motor_360'),
            renderCompImg('light_sensor'),
            renderCompImg('tact_switch'),
            renderCompImg('touch_sensor'),
            renderCompImg('ultrasonic_sensor'),
            renderCompImg('led_1c'),
            renderCompImg('tact_switch_2c'),
            renderCompImg('RGB'),
            renderCompImg('OLED'),
            renderCompImg('mp3'),
            renderCompImg('dip_switch'),
            renderCompImg('linear_pot'),
            renderCompImg('humidity'),
            renderCompImg('metal_detector'),
            renderCompImg('extender'),
            renderCompImg('temperature_sensor'),
            renderCompImg('gas'),
            renderCompImg('rain_sensor'),
            renderCompImg('pc_motor_driver'),
            renderCompImg('dual_splitter'),
            // helpscreen
            renderPrgImage('InternalPrgf1'),
            renderPrgImage('InternalPrgf2'),
            renderPrgImage('InternalPrgf3'),
            renderPrgImage('InternalPrgf4'),
            renderPrgImage('Pcv1SelectH1'),
            renderPrgImage('Pcv1SelectH2'),
            renderPrgImage('ExternalPrgf2'),
            renderPrgImage('ExternalPrgf3'),
            renderPrgImage('assemblyH'),
            renderPrgImage('Pcv1AssemblyH'),
            renderPrgImage('AssemblyPrgf1'),
            renderPrgImage('assemblyH2'),
            renderPrgImage('Pcv1AssemblyH2'),
            renderPrgImage('AssemblyPrgf2'),
            renderPrgImage('assemblyH3'),
            renderPrgImage('Pcv1AssemblyH3'),
            renderPrgImage('AssemblyPrgf3'),
            renderPrgImage('AssemblyPrgf4'),
            renderPrgImage('LogicPrgf1'),
            renderPrgImage('LogicPrgf2'),
            renderPrgImage('LogicPrgf3'),
            renderPrgImage('LogicPrgf4'),
            renderPrgImage('LogicPrgf5'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    // return <div className="InternalAccessoriesScreen_Main"></div>;
    return (
        <>
            <DialogModal
                show={erasedProgram}
                text="All Unsaved program will be Erased. Are you sure want to continue ?"
                showCloseBtn={false}
                // handleDialogModalClose={handleDialogModalClose}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => shouldErase('Yes'),
                    },
                    {
                        text: 'NO',
                        do: () => shouldErase('No'),
                    },
                ]}
            />
            <div className="InternalAccessoriesScreen_Main_v1">
                <div>
                    <ProgramHeader
                        data={{
                            options: ['Select', 'Assemble', 'Code', 'Simulate'],
                            selected: 0,
                        }}
                        showSave={true}
                        showHelp={true}
                        handleHelp={handleHelpBtn}
                    />
                </div>

                {/* {dataErased} */}

                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : ''
                    }`}
                >
                    {' '}
                    <div className="Item-1_v1">
                        <div className="InternalAccessoriesScreen-Item1_v1">
                            <div style={styleDevicePC} className="device_v1">
                                {connectedDevice === 'Roboki' ? (
                                    <div className="deviceContainer_v1">
                                        <img
                                            src={renderPrgImage(
                                                props.indexData.concept
                                                    .internalaccessories
                                                    .isLeftEye
                                                    ? 'PcinternalEYEActive_v1'
                                                    : 'PcinternalEYEInActive_v1'
                                            )}
                                            className="imgStyleTeeth_v1"
                                            style={{
                                                top: '45.5%',
                                                width: '6%',
                                                left: '41%',
                                            }}
                                            draggable="false"
                                        />
                                        <img
                                            src={renderPrgImage(
                                                props.indexData.concept
                                                    .internalaccessories
                                                    .isRightEye
                                                    ? 'PcinternalEYEActive_v1'
                                                    : 'PcinternalEYEInActive_v1'
                                            )}
                                            className="imgStyleTeeth_v1"
                                            style={{
                                                top: '45.5%',
                                                width: '6%',
                                                left: '52%',
                                            }}
                                            draggable="false"
                                        />
                                    </div>
                                ) : (
                                    <div className="deviceContainer_v1">
                                        <div>
                                            {[
                                                31.5, 38.5, 44.8, 52, 58, 64.5,
                                            ].map((value, index) => {
                                                return (
                                                    <div key={index}>
                                                        <img
                                                            src={renderPrgImage(
                                                                props.indexData
                                                                    .concept
                                                                    .internalaccessories
                                                                    .isEYES
                                                                    ? 'PcinternalEYEActive_v1'
                                                                    : 'PcinternalEYEInActive_v1'
                                                            )}
                                                            className="imgStyleEye_v1"
                                                            style={{
                                                                left: `${value}%`,
                                                                top: '49%',
                                                            }}
                                                            draggable="false"
                                                        />
                                                        <img
                                                            src={renderPrgImage(
                                                                props.indexData
                                                                    .concept
                                                                    .internalaccessories
                                                                    .isEYES
                                                                    ? 'PcinternalEYEActive_v1'
                                                                    : 'PcinternalEYEInActive_v1'
                                                            )}
                                                            className="imgStyleEye_v1"
                                                            style={{
                                                                left: `${value}%`,
                                                                top: '54.5%',
                                                            }}
                                                            draggable="false"
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <img
                                            src={renderPrgImage(
                                                props.indexData.concept
                                                    .internalaccessories
                                                    .isSmileOne
                                                    ? 'PcinternalTeethActive_v1'
                                                    : 'PcinternalTeethInActive_v1'
                                            )}
                                            className="imgStyleTeeth_v1"
                                            style={{
                                                top: '74.6%',
                                                width: '1.6%',
                                                left: '43.5%',
                                            }}
                                            draggable="false"
                                        />

                                        <img
                                            src={renderPrgImage(
                                                props.indexData.concept
                                                    .internalaccessories
                                                    .isSmileTwo
                                                    ? 'PcinternalTeethActive_v1'
                                                    : 'PcinternalTeethInActive_v1'
                                            )}
                                            className="imgStyleTeeth_v1"
                                            style={{
                                                top: '75.6%',
                                                width: '1.6%',
                                                left: '45.8%',
                                            }}
                                            draggable="false"
                                        />

                                        <img
                                            src={renderPrgImage(
                                                props.indexData.concept
                                                    .internalaccessories
                                                    .isSmileThree
                                                    ? 'PcinternalTeethActive_v1'
                                                    : 'PcinternalTeethInActive_v1'
                                            )}
                                            className="imgStyleTeeth_v1"
                                            style={{
                                                top: '75.6%',
                                                width: '1.6%',
                                                left: '53.4%',
                                            }}
                                            draggable="false"
                                        />

                                        <img
                                            src={renderPrgImage(
                                                props.indexData.concept
                                                    .internalaccessories
                                                    .isSmileFour
                                                    ? 'PcinternalTeethActive_v1'
                                                    : 'PcinternalTeethInActive_v1'
                                            )}
                                            className="imgStyleTeeth_v1"
                                            style={{
                                                top: '74.3%',
                                                left: '55.5%',
                                                width: '1.6%',
                                            }}
                                            draggable="false"
                                        />

                                        <img
                                            src={renderPrgImage(
                                                props.indexData.concept
                                                    .internalaccessories
                                                    .Four_in_one_sensor
                                                    .isDistanceSensors ||
                                                    props.indexData.concept
                                                        .internalaccessories
                                                        .Four_in_one_sensor
                                                        .isColorSensor ||
                                                    props.indexData.concept
                                                        .internalaccessories
                                                        .Four_in_one_sensor
                                                        .isGestureSensor ||
                                                    props.indexData.concept
                                                        .internalaccessories
                                                        .Four_in_one_sensor
                                                        .isLightSensor
                                                    ? 'Pcinternal4in1Active_v1'
                                                    : 'Pcinternal4in1InActive_v1'
                                            )}
                                            className="imgStyle4in1_v1"
                                            style={{
                                                top: '76%',
                                                left: '48%',
                                                width: '4%',
                                            }}
                                            draggable="false"
                                        />

                                        <img
                                            src={renderPrgImage(
                                                props.indexData.concept
                                                    .internalaccessories.isMic
                                                    ? 'PcinternalMicActive'
                                                    : 'PcinternalMicInActive'
                                            )}
                                            className="imgStyleMic_v1"
                                            style={{ left: '50%', top: '-21%' }}
                                            draggable="false"
                                        />

                                        <img
                                            src={renderPrgImage(
                                                props.indexData.concept
                                                    .internalaccessories
                                                    .isbuzzer
                                                    ? 'PcinternalBuzzerActive'
                                                    : 'PcinternalBuzzerInActive'
                                            )}
                                            className="imgStyleBuzzer_v1"
                                            style={{ left: '6%' }}
                                            draggable="false"
                                        />

                                        {props.indexData.concept
                                            .internalaccessories.isTouchZero ||
                                        props.indexData.concept
                                            .internalaccessories
                                            .isTouchZeroOutput ? (
                                            <img
                                                className="imgStyleTouchpads1_v1"
                                                style={{
                                                    bottom: '-18%',
                                                    left: '16%',
                                                    width: '19%',
                                                    height: '15%',
                                                    borderRadius:
                                                        '15% 26% 1% 13%',
                                                    background:
                                                        'rgb(255 153 64 / 94%)',
                                                }}
                                                draggable="false"
                                            />
                                        ) : (
                                            <img
                                                className="imgStyleTouchpads1_v1"
                                                style={{
                                                    bottom: '-18%',
                                                    left: '16%',
                                                    width: '19%',
                                                    height: '15%',
                                                    borderRadius:
                                                        '15% 26% 1% 13%',
                                                    background:
                                                        'rgb(176 174 161 / 95%)',
                                                }}
                                                draggable="false"
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories.isTouchOne ||
                                        props.indexData.concept
                                            .internalaccessories
                                            .isTouchOneOutput ? (
                                            <img
                                                className="imgStyleTouchpads2_v1"
                                                style={{
                                                    bottom: '-18.5%',
                                                    left: '30%',
                                                    width: '18%',
                                                    height: '15%',
                                                    borderRadius:
                                                        '15% 13% 1% 13%',
                                                    background:
                                                        'rgb(255 153 64 / 94%)',
                                                }}
                                                draggable="false"
                                            />
                                        ) : (
                                            <img
                                                className="imgStyleTouchpads2_v1"
                                                style={{
                                                    bottom: '-18.5%',
                                                    left: '30%',
                                                    width: '18%',
                                                    height: '15%',
                                                    borderRadius:
                                                        '15% 13% 1% 13%',
                                                    background:
                                                        'rgb(176 174 161 / 95%)',
                                                }}
                                                draggable="false"
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories.isTouchTwo ||
                                        props.indexData.concept
                                            .internalaccessories
                                            .isTouchTwoOutput ? (
                                            <img
                                                className="imgStyleTouchpads2_v1"
                                                style={{
                                                    bottom: '-18.5%',
                                                    left: '49.3%',
                                                    width: '18%',
                                                    height: '15%',
                                                    borderRadius:
                                                        '15% 13% 1% 13%',
                                                    background:
                                                        'rgb(255 153 64 / 94%)',
                                                }}
                                                draggable="false"
                                            />
                                        ) : (
                                            <img
                                                className="imgStyleTouchpads2_v1"
                                                style={{
                                                    bottom: '-18.5%',
                                                    left: '49.3%',
                                                    width: '18%',
                                                    height: '15%',
                                                    borderRadius:
                                                        '15% 13% 1% 13%',
                                                    background:
                                                        'rgb(176 174 161 / 95%)',
                                                }}
                                                draggable="false"
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories.isTouchThree ||
                                        props.indexData.concept
                                            .internalaccessories
                                            .isTouchThreeOutput ? (
                                            <img
                                                className="imgStyleTouchpads3_v1"
                                                style={{
                                                    bottom: '-18%',
                                                    left: '79%',
                                                    width: '17%',
                                                    height: '15%',
                                                    borderRadius:
                                                        '15% 13% 16% 13%',
                                                    background:
                                                        'rgb(255 153 64 / 94%)',
                                                }}
                                                draggable="false"
                                            />
                                        ) : (
                                            <img
                                                className="imgStyleTouchpads3_v1"
                                                style={{
                                                    bottom: '-18%',
                                                    left: '79%',
                                                    width: '17%',
                                                    height: '15%',
                                                    borderRadius:
                                                        '15% 13% 16% 13%',
                                                    background:
                                                        'rgb(176 174 161 / 95%)',
                                                }}
                                                draggable="false"
                                            />
                                        )}

                                        {/* <img
                                    src={renderPrgImage(
                                        props.indexData.concept
                                            .internalaccessories.isTouchThree
                                            ? 'PcinternalEYEActive_v1'
                                            : 'PcinternalEYEInActive_v1'
                                    )}
                                    style={{
                                        position: 'absolute',
                                        left: '46%',
                                        top: '92.5%',
                                        width: '8.5%',
                                    }}
                                    draggable="false"
                                /> */}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="InternalAccessoriesScreen-Item2_v1">
                            <div
                                className="input_v1 input_one_v1"
                                style={styleInput}
                            >
                                <p
                                    className="inputText_v1"
                                    style={{ fontFamily: 'Halcyon_SemiBold' }}
                                >
                                    Inputs
                                </p>
                                <div className="inputContiner_v1">
                                    {connectedDevice === 'Roboki' ? (
                                        <div className="container-row_v1 ">
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isSwitch1 ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'switch1Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="switch1"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'switch1InActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="switch1"
                                                    onClick={handleEventsClick}
                                                />
                                            )}{' '}
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isSwitch2 ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'switch2Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="switch2"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'switch2InActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="switch2"
                                                    onClick={handleEventsClick}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="container-row_v1 ">
                                            {props.indexData.concept
                                                .internalaccessories.isMic ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'micActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="mic"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'micInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="mic"
                                                    onClick={handleEventsClick}
                                                />
                                            )}{' '}
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isTemperature ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'tempActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="temperature"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'tempInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="temperature"
                                                    onClick={handleEventsClick}
                                                />
                                            )}{' '}
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isBattery ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'batteryActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="battery"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'batteryInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="battery"
                                                    onClick={handleEventsClick}
                                                />
                                            )}{' '}
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isAccelerometer ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'accelerometerActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="accelerometer"
                                                    onClick={handleEventsClick}
                                                    style={{
                                                        width: '18%',
                                                        height: '65.5%',
                                                        marginRight: '5%',
                                                        cursor: 'pointer',
                                                        marginBottom: '2%',
                                                    }}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'accelerometerInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="accelerometer"
                                                    onClick={handleEventsClick}
                                                    style={{
                                                        width: '18%',
                                                        height: '65.5%',
                                                        marginRight: '5%',
                                                        cursor: 'pointer',
                                                        marginBottom: '2%',
                                                    }}
                                                />
                                            )}{' '}
                                        </div>
                                    )}
                                    {connectedDevice === 'Roboki' ? (
                                        ''
                                    ) : (
                                        <div className="container-row_v1">
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isTouchZero ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch0Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch0"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch0InActive'
                                                    )}
                                                    className={`imgStyle_v1 ${
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'S1'
                                                            )
                                                        ) == true
                                                            ? 'isDisableBtn'
                                                            : ''
                                                    }`}
                                                    alt="touch0"
                                                    onClick={handleEventsClick}
                                                />
                                            )}

                                            {props.indexData.concept
                                                .internalaccessories
                                                .isTouchOne ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch1Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch1"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch1InActive'
                                                    )}
                                                    className={`imgStyle_v1 ${
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'S2'
                                                            )
                                                        ) == true
                                                            ? 'isDisableBtn'
                                                            : ''
                                                    }`}
                                                    alt="touch1"
                                                    onClick={handleEventsClick}
                                                />
                                            )}

                                            {props.indexData.concept
                                                .internalaccessories
                                                .isTouchTwo ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch2Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch2"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch2InActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch2"
                                                    onClick={handleEventsClick}
                                                />
                                            )}

                                            {props.indexData.concept
                                                .internalaccessories
                                                .isTouchThree ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch3Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch3"
                                                    onClick={handleEventsClick}
                                                    style={{
                                                        width: '20%',
                                                        height: '70.5%',
                                                        marginRight: '5%',
                                                        cursor: 'pointer',
                                                        marginBottom: '0%',
                                                    }}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch3InActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch3"
                                                    onClick={handleEventsClick}
                                                    style={{
                                                        width: '20%',
                                                        height: '70.5%',
                                                        marginRight: '5%',
                                                        cursor: 'pointer',
                                                        marginBottom: '0%',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                    {connectedDevice === 'Roboki' ? (
                                        ''
                                    ) : (
                                        <div className="container-row_v1">
                                            {props.indexData.concept
                                                .internalaccessories
                                                .Four_in_one_sensor
                                                .isDistanceSensors ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'distancesensorsActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="distancesensors"
                                                    onClick={
                                                        handleFounInOneSensor
                                                    }
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'distancesensorsInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="distancesensors"
                                                    onClick={
                                                        handleFounInOneSensor
                                                    }
                                                />
                                            )}

                                            {props.indexData.concept
                                                .internalaccessories
                                                .Four_in_one_sensor
                                                .isGestureSensor ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'gesturesensorActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    onClick={
                                                        handleFounInOneSensor
                                                    }
                                                    alt="gesturesensor"
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'gesturesensorInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    onClick={
                                                        handleFounInOneSensor
                                                    }
                                                    alt="gesturesensor"
                                                />
                                            )}

                                            {props.indexData.concept
                                                .internalaccessories
                                                .Four_in_one_sensor
                                                .isLightSensor ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'lightsensorActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    onClick={
                                                        handleFounInOneSensor
                                                    }
                                                    alt="lightsensor"
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'lightsensorInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    onClick={
                                                        handleFounInOneSensor
                                                    }
                                                    alt="lightsensor"
                                                />
                                            )}

                                            {props.indexData.concept
                                                .internalaccessories
                                                .Four_in_one_sensor
                                                .isColorSensor ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'colorsensorActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    onClick={
                                                        handleFounInOneSensor
                                                    }
                                                    alt="colorsensor"
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'colorsensorInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    onClick={
                                                        handleFounInOneSensor
                                                    }
                                                    alt="colorsensor"
                                                />
                                            )}
                                        </div>
                                    )}

                                    {showFeedback && (
                                        <div id="snackbar1" className="show">
                                            {feedbackMsg}
                                        </div>
                                    )}

                                    {isMic ? (
                                        <div id="snackbar1">
                                            PC - Microphone selected
                                        </div>
                                    ) : (
                                        <div id="snackbar1">
                                            PC - Microphone deselected
                                        </div>
                                    )}
                                    {isTemperature ? (
                                        <div id="snackbar2">
                                            PC - Temperature Sensor selected
                                        </div>
                                    ) : (
                                        <div id="snackbar2">
                                            PC - Temperature Sensor deselected
                                        </div>
                                    )}
                                    {isBattery ? (
                                        <div id="snackbar3">
                                            PC - Internal Battery selected
                                        </div>
                                    ) : (
                                        <div id="snackbar3">
                                            PC - Internal Battery deselected
                                        </div>
                                    )}
                                    {isAccelerometer ? (
                                        <div id="snackbar4">
                                            PC - Accelerometer selected
                                        </div>
                                    ) : (
                                        <div id="snackbar4">
                                            PC - Accelerometer deselected
                                        </div>
                                    )}

                                    {isTouchZero ? (
                                        <div id="snackbar5">
                                            PC - Touchpad 0 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar5">
                                            PC - Touchpad 0 deselected
                                        </div>
                                    )}

                                    {isTouchZero || isTouchZeroOutput ? null : (
                                        <div id="snackbar3_A1">
                                            {' '}
                                            Disable S1 to Enable Touch Pad 0
                                        </div>
                                    )}
                                    {isTouchOne ? (
                                        <div id="snackbar6">
                                            PC - Touchpad 1 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar6">
                                            PC - Touchpad 1 deselected
                                        </div>
                                    )}
                                    {isTouchOne || isTouchOneOutput ? null : (
                                        <div id="snackbar7_B1">
                                            {' '}
                                            Disable S2 to Enable Touch Pad 1
                                        </div>
                                    )}

                                    {isTouchTwo ? (
                                        <div id="snackbar7">
                                            PC - Touchpad 2 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar7">
                                            PC - Touchpad 2 deselected
                                        </div>
                                    )}
                                    {isTouchThree ? (
                                        <div id="snackbar8">
                                            PC - Touchpad 3 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar8">
                                            PC - Touchpad 3 deselected
                                        </div>
                                    )}

                                    {isTouchZeroOutput ? (
                                        <div id="snackbar9">
                                            PC - Touchpad 0 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar9">
                                            PC - Touchpad 0 deselected
                                        </div>
                                    )}
                                    {isTouchOneOutput ? (
                                        <div id="snackbar10">
                                            PC - Touchpad 1 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar10">
                                            PC - Touchpad 1 deselected
                                        </div>
                                    )}
                                    {isTouchTwoOutput ? (
                                        <div id="snackbar11">
                                            PC - Touchpad 2 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar11">
                                            PC - Touchpad 2 deselected
                                        </div>
                                    )}

                                    {isTouchThreeOutput ? (
                                        <div id="snackbar22">
                                            PC - Touchpad 3 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar22">
                                            PC - Touchpad 3 deselected
                                        </div>
                                    )}
                                    {isSwitch1 ? (
                                        <div id="snackbar23">
                                            Roboki - Switch 1 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar23">
                                            Roboki - switch 1 deselected
                                        </div>
                                    )}
                                    {isSwitch2 ? (
                                        <div id="snackbar24">
                                            Roboki - Switch 2 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar24">
                                            Roboki - switch 2 deselected
                                        </div>
                                    )}

                                    {isLeftEye ? (
                                        <div id="snackbar25">
                                            Roboki - Left Eye selected
                                        </div>
                                    ) : (
                                        <div id="snackbar25">
                                            Roboki - Left Eye deselected
                                        </div>
                                    )}
                                    {isRightEye ? (
                                        <div id="snackbar26">
                                            Roboki - Right Eye selected
                                        </div>
                                    ) : (
                                        <div id="snackbar26">
                                            Roboki - Right Eye deselected
                                        </div>
                                    )}

                                    {/* {isLeftEye ? (
                  <div id="snackbar111">Left Eye selected</div>
                ) : (
                  <div id="snackbar111">Left Eye deselected</div>
                )} */}
                                    {isEYES ? (
                                        <div id="snackbar12">
                                            PC - RGB leds selected
                                        </div>
                                    ) : (
                                        <div id="snackbar12">
                                            PC - RGB leds deselected
                                        </div>
                                    )}
                                    {isbuzzer ? (
                                        <div id="snackbar13">
                                            PC - Buzzer selected
                                        </div>
                                    ) : (
                                        <div id="snackbar13">
                                            PC - Buzzer deselected
                                        </div>
                                    )}

                                    {isSmileOne ? (
                                        <div id="snackbar14">
                                            PC - Smile Led 1 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar14">
                                            PC - Smile Led 1 deselected
                                        </div>
                                    )}
                                    {isSmileTwo ? (
                                        <div id="snackbar15">
                                            PC - Smile Led 2 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar15">
                                            PC - Smile Led 2 deselected
                                        </div>
                                    )}
                                    {isSmileThree ? (
                                        <div id="snackbar16">
                                            PC - Smile Led 3 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar16">
                                            PC - Smile Led 3 deselected
                                        </div>
                                    )}
                                    {isSmileFour ? (
                                        <div id="snackbar17">
                                            PC - Smile Led 4 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar17">
                                            PC - Smile Led 4 deselected
                                        </div>
                                    )}
                                    {isDistanceSensors ? (
                                        <div id="snackbar18">
                                            PC - Proximity Sensor selected
                                        </div>
                                    ) : (
                                        <div id="snackbar18">
                                            PC - Proximity Sensor deselected
                                        </div>
                                    )}
                                    {isGestureSensor ? (
                                        <div id="snackbar19">
                                            PC - Gesture Sensor selected
                                        </div>
                                    ) : (
                                        <div id="snackbar19">
                                            PC - Gesture Sensor deselected
                                        </div>
                                    )}
                                    {isLightSensor ? (
                                        <div id="snackbar20">
                                            PC - Light Sensor selected
                                        </div>
                                    ) : (
                                        <div id="snackbar20">
                                            PC - Light Sensor deselected
                                        </div>
                                    )}
                                    {isColorSensor ? (
                                        <div id="snackbar21">
                                            PC - Color Sensor selected
                                        </div>
                                    ) : (
                                        <div id="snackbar21">
                                            PC - Color Sensor deselected
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="onput_v1" style={styleoutput}>
                                <p className="txtTitle_v1">Outputs</p>
                                <div className="outputContiner_v1">
                                    {connectedDevice === 'Roboki' ? (
                                        <div className="container-row_v1">
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isLeftEye ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'lefteyeActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="leftEye"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'lefteyeInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="leftEye"
                                                    onClick={handleEventsClick}
                                                />
                                            )}{' '}
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isRightEye ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'righteyeActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="rightEye"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'righteyeInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="rightEye"
                                                    onClick={handleEventsClick}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="container-row_v1">
                                            {props.indexData.concept
                                                .internalaccessories.isEYES ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'EyeActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="isEYES"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'EyeInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="isEYES"
                                                    onClick={handleEventsClick}
                                                />
                                            )}
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isbuzzer ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'buzzerActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="buzzer"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'buzzerInActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="buzzer"
                                                    onClick={handleEventsClick}
                                                />
                                            )}
                                        </div>
                                    )}

                                    {connectedDevice === 'Roboki' ? (
                                        ''
                                    ) : (
                                        <div className="container-row_v1">
                                            {' '}
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isTouchZeroOutput ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch0Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch0Output"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch0InActive'
                                                    )}
                                                    className={`imgStyle_v1 ${
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'S1'
                                                            )
                                                        ) == true
                                                            ? 'isDisableBtn'
                                                            : ''
                                                    }`}
                                                    alt="touch0Output"
                                                    onClick={handleEventsClick}
                                                />
                                            )}
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isTouchOneOutput ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch1Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch1Output"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch1InActive'
                                                    )}
                                                    className={`imgStyle_v1 ${
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'S2'
                                                            )
                                                        ) == true
                                                            ? 'isDisableBtn'
                                                            : ''
                                                    }`}
                                                    alt="touch1Output"
                                                    onClick={handleEventsClick}
                                                />
                                            )}
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isTouchTwoOutput ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch2Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch2Output"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch2InActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch2Output"
                                                    onClick={handleEventsClick}
                                                />
                                            )}
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isTouchThreeOutput ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch3Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch3Output"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'touch3InActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="touch3Output"
                                                    onClick={handleEventsClick}
                                                />
                                            )}
                                        </div>
                                    )}

                                    {connectedDevice === 'Roboki' ? (
                                        ''
                                    ) : (
                                        <div className="container-row_v1">
                                            {props.indexData.concept
                                                .internalaccessories
                                                .isSmileOne ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'smile1Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="smile1"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'smile1InActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="smile1"
                                                    onClick={handleEventsClick}
                                                />
                                            )}

                                            {props.indexData.concept
                                                .internalaccessories
                                                .isSmileTwo ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'smile2Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="smile2"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'smile2InActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="smile2"
                                                    onClick={handleEventsClick}
                                                />
                                            )}

                                            {props.indexData.concept
                                                .internalaccessories
                                                .isSmileThree ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'smile3Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="smile3"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'smile3InActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="smile3"
                                                    onClick={handleEventsClick}
                                                />
                                            )}

                                            {props.indexData.concept
                                                .internalaccessories
                                                .isSmileFour ? (
                                                <img
                                                    src={renderPrgImage(
                                                        'smile4Active'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="smile4"
                                                    onClick={handleEventsClick}
                                                />
                                            ) : (
                                                <img
                                                    src={renderPrgImage(
                                                        'smile4InActive'
                                                    )}
                                                    className="imgStyle_v1"
                                                    alt="smile4"
                                                    onClick={handleEventsClick}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* BOTTOM BACK,NEXT BTN and discription*/}
                <div>
                    {sessionStorage.getItem('programMode') ===
                    'projectBased' ? (
                        <Footer
                            isGoBack={false}
                            goBack={backBtnAction}
                            title="Select the internal accessories of Play Computer"
                            isGoNext={true}
                            goNext={next}
                        />
                    ) : (
                        <div className="SelectScreenBottom">
                            <div className="bottom-child">
                                {/* <Link to="/programSelection"> */}
                                <img
                                    className="iconBtnSize imgBackBtn"
                                    src={renderPrgImage('backBtn')}
                                    onClick={backBtnAction}
                                />
                                {/* </Link> */}
                                <div
                                    style={{
                                        fontFamily: 'Halcyon_SemiBold',
                                        color: 'rgb(112, 112, 112)',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%,-50%)',
                                    }}
                                >
                                    <p>
                                        Select the internal accessories of Play
                                        Computer
                                    </p>
                                </div>

                                <img
                                    className="iconBtnSize imgNextBtn"
                                    src={renderPrgImage('nextBtn')}
                                    onClick={next}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    {isHelp && (
                        <div
                            className="Ss_slide"
                            style={{
                                top: '49vh%',
                                left: '50%',
                                width: '101vw',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            {' '}
                            <InternalScPrgm />{' '}
                            <img
                                className="helpClo"
                                src={renderPrgImage('closBtn')}
                                onClick={handleHelpBtn}
                            ></img>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    // return state;
    return {
        indexData: state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selecteComponent: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
        logicComponent: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InternalAccessoriesScreen_v1)
