import * as atatus from 'atatus-spa'
import React, { Component, useEffect } from 'react'
import GoogleLogin from 'react-google-login'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import renderImage from '../../source/importImg'
import PortConnections from '../Assembly/PortConnections'
import components from '../concept/data' //component details
import './googleloginbtn.scss'
import './login.css'
import GoogleLoginBtn from './googleLoginBtn'
import animationStyle from './loginAnimation.module.css'
import renderPrgImage from '../../source/programImg'
import ReactLoading from 'react-loading'

class login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowLoader: false,
            slideDirection: sessionStorage.getItem('slideDirection'),
        }
        this.isWindowOnFocus = sessionStorage.getItem('isWindowOnFocus')
        sessionStorage.clear()
    }
    componentDidMount = () => {
        // sessionStorage.clear()
        sessionStorage.setItem('isWindowOnFocus', this.isWindowOnFocus)
        var concept = { counter: [], componentProps: {} }
        var assembly = {
            components: components,
            PortConnections: PortConnections,
            workspace: {
                // bibox: { top: 100, left: 250 },
                bibox: { top: 208, left: 328 },
                components: {
                    // Other components come here
                    // eg. "led": [{top: 20, left: 80, connectedTo: 'A1'}, ...], ...
                },
                offset: { top: 0, left: 0 },
                scale: 1,
            },

            height: document.body.clientHeight,
            width: document.body.clientWidth,
        }
        var logic = {
            program: [
                {
                    type: 'start',
                    state: {
                        bic1: false,
                        bic2: false,
                        bic3: false,
                        bid2: false,
                        bif1: false,
                        bif2: false,
                        bif3: false,
                    },
                    bic1: false,
                    bic2: false,
                    bic3: false,
                    bid2: false,
                    bif1: false,
                    bif2: false,
                    bif3: false,
                    bid3: false,
                    bid1: false,
                    bmp3: false,
                },
            ],
            end: { type: 'end', state: 'repeat' },
            insertState: false,

            offset: { left: 0, top: 0 },
            scale: 1,
            currentProgramGuide: 0,
            active: [-1, -1],
            bottomPanel: 'border',
        }

        if (this.props.webSerial.port) {
            console.log('yes')
        }
        console.log('LOGIC', concept, assembly, logic, this)
        this.props.clearComponent()
        this.props.selecteComponent(concept)
        this.props.assemblyComponent(assembly)
        this.props.logicComponent(logic)
        fetch('https://c.plode.org/verify', {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                token: localStorage.getItem('accessToken'),
            }),
        })
            .then((res) => {
                if (!res.ok) localStorage.removeItem('userJWT')
                else if (localStorage.getItem('userJWT')) {
                    setTimeout(() => {
                        this.props.history.push('/kitSelection')
                    }, 4200)
                }
            })
            .catch((e) => {
                if (localStorage.getItem('userJWT')) {
                    setTimeout(() => {
                        this.props.history.push('/kitSelection')
                        alert(
                            "Our build server is currently down due to high demand. Some features may be temporarily unavailable. We're on it and appreciate your patience!"
                        )
                    }, 4200)
                }
            })
    }

    setIsShowLoader = (value) => {
        this.setState({ isShowLoader: value })
    }
    render() {
        // sessionStorage.clear()
        sessionStorage.removeItem('connectText')
        sessionStorage.removeItem('concept')
        let userJwt = localStorage.getItem('userJWT') ? true : false
        let accessToken = localStorage.getItem('accessToken') ? true : false
        const loginFail = (response) => {
            window.alert(
                'Sign in with Google failed try again with a different Google account'
            )
            console.log(response)
        }
        const loginSuccess = (response) => {
            sessionStorage.setItem(
                'userData',
                JSON.stringify(response.profileObj)
            )
            atatus.setUser(
                response.profileObj.googleId,
                response.profileObj.email,
                response.profileObj.givenName +
                    ' ' +
                    response.profileObj.familyName
            )
            this.props.history.push('/kitSelection')
        }
        const { slideDirection } = this.state
        return (
            <div
                className={`${slideDirection === 'true' ? 'slide-right' : ''}`}
            >
                {' '}
                <div className={animationStyle.MainSec}>
                    <div
                        id={animationStyle.MainSeclogo}
                        style={{
                            backgroundImage: `url("${renderPrgImage(
                                'yellowBg'
                            )}")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '65% 100%',
                        }}
                    >
                        <div className={animationStyle.Sub_Main_Title}>
                            <img
                                className={animationStyle.Main_Sec_Title}
                                src={renderImage('plodeIconSvg')}
                            ></img>
                        </div>
                        <div className={animationStyle.SubMain}>
                            <img
                                className={animationStyle.Main_Sec_Rock}
                                src={renderImage('leadingPageSvg')}
                            ></img>
                        </div>
                    </div>

                    {(!userJwt || !accessToken) && (
                        <div id={animationStyle.Google_Login}>
                            <div>
                                {' '}
                                <GoogleLoginBtn
                                    history={this.props.history}
                                    setIsShowLoader={this.setIsShowLoader}
                                ></GoogleLoginBtn>
                                {/* <GoogleLogin
            clientId="798914613502-eeirsjatcut3f8pljkbknd1hdkampga8.apps.googleusercontent.com"
            buttonText="Login"
            render={(renderProps) => (
              <div
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <div className="google-btn">
                  <div className="google-icon-wrapper">
                    <img
                      className="google-icon"
                      src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                    />
                  </div>
                  <p className="btn-text">
                    <b> Sign in with google</b>
                  </p>
                </div>
              </div>
            )}
            onSuccess={loginSuccess}
            onFailure={loginFail}
            cookiePolicy={"single_host_origin"}
          /> */}
                            </div>
                        </div>
                    )}
                    {this.state.isShowLoader && (
                        <div
                            style={{
                                position: 'absolute',
                                backgroundColor: 'rgb(210, 211, 211, 0.5)',
                                height: '100vh',
                                width: '100vw',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <ReactLoading
                                type="spin"
                                color="gray"
                                height={100}
                                width={100}
                            />
                        </div>
                    )}

                    {/* --------------- youdu robotics logo ------------------ */}
                    <div className={animationStyle.bottomTextDiv}>
                        <p className={animationStyle.copyRightText}>
                            <span style={{ fontSize: '1.1rem' }}>&copy;</span>
                            {'  '}
                            PLAYFULLY CRAFTED BY YUDU ROBOTICS
                        </p>
                    </div>
                    {/* <div
                        style={{
                            position: 'fixed',
                            right: '180px',
                            bottom: '90px',
                        }}
                    >
                        <img
                            className={animationStyle.Main_Sec_Title}
                            src={renderPrgImage('yuduRoboticsLogo')}
                            alt="Yudu Robotics"
                        />
                    </div> */}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        webSerial: state.webSerial,
        worker: state.worker,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selecteComponent: (data) => {
            dispatch({ type: 'COMPONENT', payload: data })
        },

        assemblyComponent: (data) => {
            dispatch({ type: 'ASSEMBLY', payload: data })
        },
        logicComponent: (data) => {
            dispatch({ type: 'LOGIC_RESET', payload: data }) //
        },
        clearComponent: (data) => {
            dispatch({ type: 'CLEAR_COMPONENT_SELECTION' }) //INTERNAL_ACCESSORIES
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(login))

// export default login
